import React, { useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import pzLogo from "../assets/images/payzeep-logo.png";
import menuIcon from "../assets/icons/menu.svg";
import closeIcon from "../assets/icons/close.svg";
import "../styles/nav.css";

const Navigation = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToRegistration = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/register";
  };

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <>
      <nav className="nav-bar">
        <div className="nav-header">
          <div className="nav-header-items">
            <img
              src={pzLogo}
              alt="payzeep logo"
              onClick={navigateToHome}
              style={{ cursor: "pointer" }}
              className="pzLogo"
            />
             {isNavVisible ? (
              <img
                src={closeIcon}
                alt="close"
                onClick={toggleNav}
                className="hamburger"
              />
            ) : (
              <img
                src={menuIcon}
                alt="menu"
                onClick={toggleNav}
                className="hamburger"
              />
            )}
          </div>
        </div>

        <div className={`nav-links ${isNavVisible ? "visible" : ""}`}>
          {
            location.pathname === "/developers" ? (
              <div className="v-links">
                <NavLink
                  to="/api-documentation"
                  className="nav-link"
                  activeClassName="active-link"
                >
                  API Documentation
                </NavLink>

                <NavLink
                  to="/product-guide"
                  className="nav-link"
                  activeClassName="active-link"
                >
                  Product Guide
                </NavLink>
              </div>
            ) : (
            <>
            <NavLink
            exact
            to="/pricing"
            className="nav-link"
            activeClassName="active-link"
          >
            Pricing
          </NavLink>
          <NavLink
            to={"/developers"}
            className="nav-link"
            activeClassName="active-link"
          >
            For Developers
          </NavLink>
          <NavLink
            to={"/frequently-asked-questions"}
            className="nav-link"
            activeClassName="active-link"
          >
            FAQs
          </NavLink>
          <NavLink
            to={"/contact-us"}
            className="nav-link"
            activeClassName="active-link"
          >
            Contact Us
          </NavLink>
          </>)
          }
          
          <div className="nav-action">
            <a
              href="https://dashboard.zeepway.com/auth/login"
              className="nav-link ghost-variant"
              activeClassName="active-link"
              target="_blank"
              rel="noreferrer"
            >
              Login
            </a>

            <button className="create-btn" onClick={navigateToRegistration}>
              Create Free Account
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
