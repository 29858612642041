import signUpImg from "../assets/images/signUpImg.svg";

const GetStarted2 = () => {
  const navigateToRegistration = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/register";
  };

  return (
    <div className="create-account">
      <div className="create-account-text">
        <h2>Ready to Get Started?</h2>
        <p>
          Create an account and instantly start
          <br />
          accepting payments today.
        </p>
        <div className="flex-btns">
          <button style={{ backgroundColor: "#0516C8" }} onClick={navigateToRegistration}>
            Create Free Account
          </button>
          <button style={{ backgroundColor: "#23242F" }}>Contact Sales</button>
        </div>
      </div>
      <div>
        <img src={signUpImg} alt="sign up" />
      </div>
    </div>
  );
};

export default GetStarted2;