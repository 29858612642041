import { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import GetStarted2 from "../components/GetStarted2";
import "../styles/demo.css";

const CheckoutDemo = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [checkoutReady, setCheckoutReady] = useState(false);

  useEffect(() => {
    if (window.CheckoutModal) {
      setCheckoutReady(true);
      return;
    }

    const script = document.createElement("script");
    script.src = "https://paymi-tech.github.io/zeepway-checkout-plugin/checkout-modal.min.js";
    script.async = true;
    script.onload = () => setCheckoutReady(true);
    script.onerror = () => {
      console.error("Failed to load checkout script");
      setLoading(false);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = (amount) => {
    if (!email) {
      alert("Please enter your email address to receive payment receipt");
      return;
    }

    setActiveButton(amount.toString());

    if (!checkoutReady) {
      alert("Payment system is still loading, please try again shortly");
      return;
    }

    setLoading(true);

    try {
      const checkout = new window.CheckoutModal();
      checkout.open({
        apiKey: "TEST_PUK_6b310eabd197c8536f535a206fbae19940b2c4fa3fad02dfe1",
        amount: amount,
        email: email,
        name: "Demo User",
        clientRef: "demo_ref_" + Date.now(),
        chargeUser: false,
        onClose: () => {setLoading(false); setActiveButton(null)}
      });
    } catch (error) {
      console.error("Error initializing checkout:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Navigation />
      <div className="demo-body">
        <div className="demo-header">
          <h2>Zeepway Checkout Demo</h2>
          <p>
            To experience Zeepway firsthand, simply make a live payment. Select a comfortable amount to test the
            service. Remember, this is an actual transaction that will debit your account.
          </p>
        </div>

        <div className="demo-form">
          <form onSubmit={(e) => e.preventDefault()}>
            <label>Enter your email to get payment receipt</label>
            <br />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="xyz@mail.com" />
            <div className="demo-pay-buttons">
              <button
                type="button"
                onClick={() => handlePayment(50)}
                disabled={activeButton !== null && activeButton !== "50"}
              >
                {loading && activeButton === "50" ? "Processing..." : "Pay NGN 50"}
              </button>
              <button
                type="button"
                onClick={() => handlePayment(500)}
                disabled={activeButton !== null && activeButton !== "500"}
              >
                {loading && activeButton === "500" ? "Processing..." : "Pay NGN 500"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <GetStarted2 />
      <Footer />
    </>
  );
};

export default CheckoutDemo;