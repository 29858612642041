import integrationSecurity from "../assets/images/integration-security.png";
import webhooks from "../assets/images/webhooks.png";
import seamlessPayment from "../assets/images/seamless-payment.png";
import apiReference from "../assets/images/api-reference.png";

export const api_features = [
	{
		src: integrationSecurity,
		title: "Secure Your Integration",
		content:
			"To access the API, generate your API keys from the dashboard. Use these credentials for authentication in every request.",
		link: "/",
	},
	{
		src: seamlessPayment,
		title: "Receive Payments Seamlessly",
		content:
			"Integrate payments via cards, or bank transfers using simple API calls. Get real-time webhook notifications and automatic settlements.",
		link: "/",
	},
	{
		src: webhooks,
		title: "Stay Updated with Webhooks",
		content:
			"Get instant transaction updates via webhooks, Set up event listeners for payment status changes and automate your workflow.",
		link: "/",
	},
	{
		src: apiReference,
		title: "Comprehensive API Reference",
		content:
			"Explore our API endpoints, parameters, request/response  formats for quick implementation.",
		link: "/",
	},
];
