import productGuide from "../assets/images/product-guide.png";
import apiDocs from "../assets/images/api-docs.png";
import integration from "../assets/images/integration.png";
import onboarding from "../assets/images/onboarding.png";
import welcome from "../assets/images/welcome.png";
import payment from "../assets/icons/payment.svg";
import transaction from "../assets/icons/transaction.svg";
import analysis from "../assets/icons/analysis.svg";
import compliance from "../assets/icons/compliance.svg";
import account from "../assets/icons/account.svg";
import phone from "../assets/icons/phone.svg";
import email from "../assets/icons/email.svg";
import x from "../assets/icons/x.svg";
import facebook from "../assets/icons/face-book.svg";
import linkedin from "../assets/icons/linkedin.svg";
import instagram from "../assets/icons/insta.svg";
import chevron from "../assets/icons/chevron-wr.svg";
import arrow from "../assets/icons/chevron-br.svg";
import successfulPayment from "../assets/images/payment.png";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { api_features } from "../constants";
import { Link } from "react-router-dom";
import "../styles/api.css";

const ApiDocumentation = () => {
	return (
		<>
			<Navigation />

			<section className="api-docs">
				<div className="api-welcome">
					<div className="api-welcome-content">
						<p>Welcome to PayZeep's API</p>

						<h4>Integrate PayZeep's API Payments with Ease</h4>

						<p>
							Quickly set up, test, and deploy with our developer-friendly API.
						</p>

						<Link
							to="https://dashboard.zeepway.com/auth/register"
							className="api-button api-btn">
							Get Started
							<img src={chevron} alt="chevron" className="chevron-right" />
						</Link>
					</div>

					<div className="api-welcome-content img-wrapper">
						<img src={welcome} alt="welcome" />
					</div>
				</div>

				<div className="api-support">
					<div className="api-support-content">
						<div className="api-support-wrapper">
							<img src={successfulPayment} alt="successful-payment" />
						</div>

						<div>
							<h4>Why PayZeep's API</h4>

							<p>
								Discover everything you need to integrate PayZeep's API
								seamlessly. Our developer-friendly documentation provides clear
								guides, sample codes, and real-time support to streamline your
								integration process.
							</p>

							<ul>
								<li>
									<img src={payment} alt="" className="api-svg" /> Payment
									Processing
								</li>

								<li>
									<img src={account} alt="" className="api-svg" />
									Account Management
								</li>

								<li>
									<img src={transaction} alt="" className="api-svg" />
									Transaction Management
								</li>

								<li>
									<img src={compliance} alt="" className="api-svg" />
									Security and Compliance
								</li>

								<li>
									<img src={analysis} alt="" className="api-svg" />
									Reporting and Analysis
								</li>
							</ul>

							<Link
								to="https://dashboard.zeepway.com/auth/register"
								className="api-button api-btn">
								Get Started
								<img src={chevron} alt="chevron" className="chevron-right" />
							</Link>
						</div>
					</div>
				</div>

				<div className="get-started">
					<h4>Get Started</h4>

					<div className="get-started-content">
						<div>
							<img src={productGuide} alt="product-guide" />
						</div>

						<div>
							<img src={apiDocs} alt="api-docs" />
						</div>
					</div>
				</div>

				<div className="api-features">
					{api_features?.map((feature, index) => (
						<div key={index} className="api-features-content">
							<div className="api-features-wrapper">
								<img src={feature.src} alt="integration-security" />
							</div>

							<div className="api-features-content-cont api-features-content-wrapper">
								<div>
									<h4>{feature.title}</h4>
									<p>{feature.content}</p>
								</div>

								<Link
									to="https://dashboard.zeepway.com/auth/register"
									className="api-btn">
									View API Docs
									<img src={arrow} alt="arrow" className="chevron-right" />
								</Link>
							</div>
						</div>
					))}
				</div>

				<div className="api-integration">
					<div className="api-integration-wrapper">
						<img src={integration} alt="integration-security" />
					</div>

					<div className="api-integration-socials">
						<Link
              to="tel:+2349088220236"
              className="api-integration-social"
              >
							<img src={phone} alt="phone" />
               +234 908 822 0236
            </Link>

						<Link
              to="mailto:projects@payzeep.com"
              className="api-integration-social"
              >
								<img src={email} alt="email" />
                projects@payzeep.com
            </Link>

						<span className="api-integration-social">
							<img src={instagram} alt="instagram" onClick={() => window.open("https://www.instagram.com/payzeep", "_blank")} />
							<img src={linkedin} alt="linkedin" onClick={() => window.open("https://www.linkedin.com/payzeep", "_blank")} />
							<img src={facebook} alt="facebook" onClick={() => window.open("https://www.facebook.com/payzeep", "_blank")} />
							<img src={x} alt="x" onClick={() => window.open("https://x.com/payzeepng", "_blank")} />
						</span>
					</div>
				</div>

				<div className="api-ready">
					<div className="api-ready-content">
						<h4>Ready to Get Started?</h4>
						<p>
							Create an account and instantly start accepting payments today.
						</p>

						<div className="api-ready-content-buttons">
							<Link
								to="https://dashboard.zeepway.com/auth/register"
								className="api-button">
								Create Free Account
							</Link>

							<Link to="/contact-sales" className="api-btn-sales">
								Contact Sales
							</Link>
						</div>
					</div>

					<div className="api-ready-content img-wrapper">
						<img src={onboarding} alt="onboarding" />
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
};

export default ApiDocumentation;
