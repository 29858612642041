import React from "react";
import { Link } from "react-router-dom";
import pzLogo from "../assets/images/payzeep-logo.png";
import facebookIcon from "../assets/icons/facebook.svg";
import twitterIcon from "../assets/icons/twitter.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import "../styles/footer.css";

const Footer = () => {
  const navigateToFacebook = () => {
    window.location.href = "https://www.facebook.com/payzeep";
  };

  const navigateToTwitter = () => {
    window.location.href = "https://twitter.com/payzeepng";
  };

  const navigateToInstagram = () => {
    window.location.href = "https://www.instagram.com/payzeep/";
  };

  return (
    <>
      <div className="footer-section">
        <div className="footer-container">
          <div className="footer-img-wrapper">
            <img src={pzLogo} alt="logo" className="footer-logo" />
          </div>

          <div className="footer-body">
            <div className="footer-column">
              <h5>Menu</h5>

              <div className="footer-links menu-links">
                <Link to="/pricing" className="footer-link">
                  Pricing
                </Link>

                <Link to="/developers" className="footer-link">
                  For Developers
                </Link>

                <Link
                  to="/frequently-asked-questions"
                  className="footer-link"
                >
                  FAQs
                </Link>
              </div>
            </div>

            <div className="footer-column">
              <h5>Contact</h5>

              <div className="footer-links">
                <p>Lagos: 13 Town Planning Way, Ilupeju, Lagos State</p>
                <p>Abuja: 36, Nouckachott Avenue, Wuse, Zone 1, Abuja</p>
                <p>(+234)9088220236</p>
                <a href="mailto:sales@payzeep.com?subject=Interested%20In%20Working%20With%20You">
                  Support@payzeep.com
                </a>
              </div>
              
              <div className="footer-icons">
                <img
                  src={facebookIcon}
                  alt="facebook"
                  onClick={navigateToFacebook}
                />
                
                <img
                  src={twitterIcon}
                  alt="twitter"
                  onClick={navigateToTwitter}
                />

                <img
                  src={instagramIcon}
                  alt="instagram"
                  onClick={navigateToInstagram}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-links">
          <div className="copyright">
            <span>© {new Date().getFullYear()} PayZeep &nbsp; &nbsp; Powered by Paymi Solutions</span>
          </div>

          <div className="terms">
            <a href="https://www.payzeep.com/privacy-policy-2" target="_blank" rel="noreferrer">
              Terms & conditions
            </a>
   
            <a href="https://www.payzeep.com/privacy-policy-2" target="_blank" rel="noreferrer">
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;